<template>
  <div class="allmsg">
    <navigation :msg="msg"></navigation>
    <pre>

<h1>用户协议</h1>
    您（用户）在使用技术开发方（即上海猎后征信服务有限公司，以下统称“技术服务方”）提供的各项服务之前，应仔细阅读本《用户协议》、<a id="aaa">《隐私政策》</a>。<strong>用户一旦登录使用技术开发方的服务，即视为用户已了解并明示同意本协议各项内容，本协议立即在用户与本技术开发方之间产生法律效力。</strong>用户使用本产品服务的全部活动将受到本协议的约束并承担相应的责任和义务。<strong>如用户不同意本协议任何内容的，请用户立即停止使用技术开发方所提供的全部服务。</strong>
    
    根据《中华人民共和国网络安全法》、《电信和互联网用户个人信息保护规定》及相关法律法规的规定，同时依据技术开发方与其合作伙伴之间的相关协议，<strong>用户必须已明示授权用户拟应聘公司（以下简称“应聘公司”）、在此明示授权并委托技术开发方及特定服务提供方（以下简称“技术服务提供方”）通过官方或相关平台采集、查询、验证、存储、在约定范围内按照约定方式使用用户的个人信息，并输出给应聘公司，由应聘公司对用户的被调结果进行评估与参考使用。</strong>

    用户理解并同意，具体的授权采集、查询、验证、存储及输出的内容以应聘公司要求采集、查询、验证、存储、输出以及技术服务提供方实际采集、查询、验证、存储、输出的信息为准，详见《<a href="www.baidu.com">隐私政策</a>》。技术服务提供方会在授权范围内对相关个人信息予以处理（包括但不限于为保护用户个人信息而加密处理、掩码处理等一切为实现相关协议目的而进行的所有必要处理）并仅提供给应聘公司使用，但<strong>用户与应聘公司之间因授权的有效性、授权内容、授权范围、授权期限等发生的争议纠纷与技术开发方无关。</strong>用户授权技术开发方可以从合法保存有用户信息的第三方合作伙伴，采集及处理用户的各类信息。<strong>如用户对应聘公司的上述授权事项有任何异议或争议的，应立即停止使用技术开发方所提供的全部服务。</strong>
    
    <strong>用户使用本服务的，即表明用户已明示对合作伙伴、技术开发方授权采集、查询、验证、处理、存储，在约定范围内按照约定方式处理其个人信息，并对授权的效力、查询验证的内容、查询验证平台、处理方式、使用范围等相关事项无任何事实或法律上的异议或争议。</strong>
    
    鉴于用户须授权应聘公司并由该应聘公司发起本服务后方能进入并使用本服务，用户使用本服务时起即视为用户与技术开发方的应聘公司之间已存在合法的、充分的、必要的、不可撤销的授权，且<strong>用户已清楚知晓其授权提供相关信息可能对其造成的可能不利影响，如负面的评价等。为保护用户个人信息，技术开发方会采取合理措施对用户信息进行严格保密，同时督促并要求该应聘公司及技术服务方进行严格保密。未经用户授权，技术开发方不会将用户信息提供给任何其他方。</strong>

<h2>一、协议主体</h2>
    本协议是用户与技术开发方关于用户使用本服务所订立的协议。

<h2>二、关于本服务</h2>
    1. 本服务内容是指技术开发方通过本应用程序向其应聘公司提供的职业背景调查服务（简称“本服务”）。
    2. 对用户使用的本服务，技术开发方会不断丰富用户使用本服务的终端、形式等。
    3．许可的范围
        （1）技术开发方授予用户一项个人的、不可转让及非排他性的许可，以使用本应用程序。
        （2）本条及本协议其他条款未明示授权的其他一切知识产权权利仍由技术开发方保留。技术开发方如果未行使前述任何权利，并不构成对该权利的放弃。

<h2>三、链接登录</h2>
    为使用本应用程序用户可能需要输入个人信息进行登录与使用。

    <strong>用户清楚知晓，技术开发方提供的信息授权链接仅限本人使用</strong>，否则，用户可能会对用户或他人造成侵权。<strong>用户承诺并同意，使用同一链接、同一设备、同一身份证号、同一手机号码使用本协议项下服务的</strong>，均视为用户本人的行为。用户应妥善保管链接、自身设备、身份证件及手机号码，审慎交由他人使用或使用他人链接、设备、身份证件或手机号码，如用户违反本约定给用户或他人造成损失的，技术开发方不应也不会承担任何法律责任。用户若因此给技术开发方造成损失的，应承担技术开发方所遭受的全部损失。

<h2>四、应用程序的使用</h2>
    如果用户从非技术开发方合作伙伴的应用程序或非技术开发方合作伙伴处获取本应用程序或与本应用程序名称相同的安装程序，技术开发方无法保证该应用程序能够正常使用，并对因此给用户造成的损失不予负责。

<h2>五、应用程序的更新</h2>
    1.为了增进用户体验、完善服务内容，技术开发方将不断努力为用户时时提供应用程序更新（这些更新可能会采取应用程序替换、修改、功能强化、版本升级等形式）。
    2.为了改善用户体验，并保证服务的安全性和功能的一致性，技术开发方有权不经向用户特别通知而对应用程序进行更新，或者对应用程序的部分功能效果进行改变或限制。

<h2>六、用户个人信息保护</h2>
    1.保护用户个人信息是技术开发方的一项基本原则，技术开发方将会采取合理的措施保护用户的个人信息。除法律法规规定及用户授权的情形外，未经用户许可技术开发方不会向任何第三方公开、透露用户个人信息。
    2.用户在使用本服务的过程中，可能需要填写一些必要的信息。若用户填写的信息不真实或不完整，则可能无法正常使用本服务。
    3.技术开发方将运用各种安全技术和程序建立完善的管理制度来保护用户的个人信息，以免遭受未经授权的访问、使用或披露。详情请参阅<a>《隐私政策》</a>

<h2>七、授权事项及行为规范</h2>
（一）授权事项
    1.用户充分理解并同意：用户在使用本服务时，为识别账号异常状态、了解产品适配性，从而为用户提供更好的服务体验，可能需要使用用户终端设备的相关权限、接口及相关设备信息等才能实现相应的功能，具体可能涉及以下事项或内容：
      （1）设备信息：当用户使用本服务时，技术开发方会记录用户使用的设备相关信息，包括设备名称、设备型号、操作系统版本、设备设置、设备环境等软硬件特征信息。
      （2）服务日志信息：当用户使用本服务时，技术开发方会收集用户的使用情况，作为服务日志保存，包括浏览、按键点击、业务操作事件类型（例如：拍照学历证书、职位证书上传等）、业务操作时间统计（例如操作耗时、<strong>勾选同意用户协议时间</strong>等）。其中为了帮助用户定位与解决服务过程中可能存在的问题，技术开发方服务日志详情如下：
        a.<strong>用户点击勾选协议，点击协议内容（含<a>隐私政策</a>）</strong>；
        b.用户点击返回或退出；
        c.用户点击拍照、点击相册上传；
        d.用户点击确定或取消键；
        e.用户点击修改姓名或身份证号码；
        f.基于用户操作拍照的统计信息：开始时间、结束时间、耗时等。
      （3）<strong>请注意，单独的设备信息、服务日志信息无法识别特定自然人身份。</strong>如果技术开发方将这类非个人信息与其他信息结合用于识别特定自然人身份，或者将其与个人信息结合使用，在结合使用期间，这类信息将被视为个人信息由技术开发方采取必要合理措施进行加密或脱敏处理，除非取得用户授权或法律另有规定外，技术开发方不会将这类信息以任何形式提供给任何其它第三方。
    2.用户可以选择不向技术开发方提供用户的某些信息，但因此可能会导致相关服务功能无法实现。
    3. 为实现本协议目的为合作伙伴及用户提供更加优质、安全的服务，<strong>用户同意并明示授权技术服务提供方对用户的相关个人信息进行采集、查询、验证、存储、处理并在约定范围内使用（提供给用户已授权的应聘公司对用户进行评估及参考使用）。</strong>技术服务提供方对用户的个人信息进行严格保密。本协议项下的授权为不可撤销的授权。
    4. 用户知晓并明示授权同意技术服务提供方依据相关法律法规的规定，<strong>受应聘公司委托向第三方权威机构等合法查询、验证、审核用户信息，</strong>上述信息详情请参阅<a>《隐私政策》</a>。
技术开发方对所获取的信息，仅在用户与合作伙伴之间职业背景调查中使用。技术开发方将对所获取的信息向该合作伙伴进行提供，除此之外，未经用户授权，技术开发方不得也不会向其他机构或个人泄露、披露或提供用户的信息。
（二）授权期限
    为实现本协议目的，用户同意并明示授权用户相关个人信息给技术服务提供方，且该授权期限同技术开发方与应聘公司的合作期限。
（三）用户禁止行为
    除非法律允许或技术开发方书面许可，用户不得从事下列行为：
    1.删除本应用程序及其副本上关于著作权的信息；
    2.对本应用程序进行反向工程、反向汇编、反向编译，或者以其他方式尝试发现本应用程序的源代码；
    3.对技术开发方拥有知识产权的内容进行使用、出租、出借、复制、修改、链接、转载、汇编、发表、出版等；
    4.通过修改或伪造应用程序运行中的指令、数据，增加、删减、变动应用程序的功能或运行效果，或者将用于上述用途的应用程序、方法进行运营或向公众传播，无论这些行为是否为商业目的；
    5.自行、授权他人或利用第三方应用程序对本应用程序及其组件、模块、数据等进行干扰；
    6.其他未经技术开发方明示授权的行为。
（四）对自己行为负责
    <strong>用户充分了解并同意，用户必须为自己对应聘公司的授权（包括但不限于授权方式、授权内容及授权期限等）以及其链接下的相关行为负责。技术服务提供方会督促所有的合作伙伴获取用户的授权后方能对相关信息进行查询、验证或使用并要求合作伙伴对用户的信息进行严格保密，但用户应对使用本服务时接触到的内容自行加以判断，如对授权相关事项及信息安全有任何异议或争议的，应立即停止使用本服务。技术开发方无法且不会对用户与应聘公司之间的任何争议或纠纷而承担责任，用户未按约定要求立即停止使用本服务的，技术开发方对非因技术开发方的原因而产生的任何风险或损失将不承担任何责任。</strong>

<h2>八、知识产权声明</h2>
    1.技术开发方是本应用程序的知识产权权利人。本应用程序的著作权、商标权、专利权、商业秘密等知识产权，以及与本应用程序相关的所有信息内容（包括但不限于文字、图片、音频、视频、图表、界面设计、版面框架、有关数据或电子文档等）均受中华人民共和国法律法规和相应的国际条约保护，技术开发方依法享有上述相关知识产权，但相关权利人依照法律规定应享有的权利除外。
    2.未经技术开发方或相关权利人书面同意，用户不得为任何商业或非商业目的自行或许可任何第三方实施、利用、转让上述知识产权。

<h2>九、终端安全责任</h2>
    1.用户理解并同意，本应用程序或本服务同大多数互联网应用程序、服务一样，可能会受多种因素影响（包括但不限于用户原因、网络服务质量、社会环境等）；也可能会受各种安全问题的侵扰（包括但不限于他人非法利用用户资料，进行现实中的骚扰；用户下载安装的其他应用程序或访问的其他网站中可能含有病毒、木马程序或其他恶意程序，威胁用户终端的信息和数据的安全，继而影响本应用程序、本服务的正常使用等）。因此，用户应加强信息安全及个人信息的保护意识，注意密码保护，以免遭受损失。出现上述情况时技术开发方将努力在第一时间与相关方配合，及时进行修复，但是由此给用户造成的损失技术开发方在法律允许的范围内免责。
    2.用户不得制作、发布、使用、传播用于窃取技术开发方账号及他人个人信息、财产的恶意程序。
    3.维护应用程序安全与正常使用是技术开发方和用户的共同责任，技术开发方将按照行业标准合理审慎地采取必要技术措施保护用户的终端信息和数据安全。
    4.在法律允许的范围内，技术开发方对以下情形导致的服务中断或受阻不承担责任：
     （1）受到计算机病毒、木马或其他恶意程序、黑客攻击的破坏；
     （2）用户或技术开发方的电脑软件、系统、硬件和通信线路出现故障；
     （3）用户操作不当；
     （4）用户通过非技术开发方授权的方式使用本服务；
     （5）其他技术开发方无法控制或合理预见的情形。

<h2>十、不可抗力及合理免责</h2>
    1. “不可抗力”是指在本协议签订后发生的、受影响一方无法预见、无法避免并无法克服的客观情况。此等事件包括但不限于水灾、火灾、旱灾、台风、地震及其它自然灾害、罢工、骚动、暴乱及战争以及政府部门的作为或不作为、法律法规或政策调整、数据来源变更(包括但不限于其服务内容及形式的变更)、国内数据渠道瘫痪、黑客攻击、计算机病毒侵入、新型病毒爆发、因电信运营商问题导致网络中断服务器不可访问、停电、系统故障、传输线路、通信故障等技术开发方无法控制的因素。因受不可抗力影响而不能履行或不能完全履行本协议的不视为违约，不应承担相应违约责任。
    2.<strong>技术开发方提供本项服务不构成对用户及用户授权的应聘公司在内的任何人之明示或暗示的观点，亦不能视为代替用户及用户授权的应聘公司进行任何形式的评估、判断或决策等。</strong>在任何情况下，应聘公司依据技术提供方得出的职业背调报告等而对其用户做出的决定或者采取的行动不会也不应由技术提供方承担任何法律责任。

<h2>十一、其他</h2>
    <strong>1.用户使用本应用程序或本服务即视为用户已阅读并同意受本协议的约束。技术开发方有权在必要时修改本协议条款。用户可以在本应用程序、本服务的最新版本中查阅相关协议条款。本协议条款变更后，如果用户继续登录、使用本应用程序、本服务，即视为用户已接受修改后的协议。如果用户不接受修改后的协议，应当停止使用本应用程序。</strong>
    2.本协议的成立、生效、履行、解释及纠纷解决，适用中华人民共和国大陆地区法律（不包括冲突法）。
    3.若用户和技术开发方之间发生任何纠纷或争议，首先应友好协商解决；协商不成的，用户同意将纠纷或争议提交被告方所在地人民法院管辖。
    4.本协议所有条款的标题仅为阅读方便，本身并无实际涵义，不能作为本协议涵义解释的依据。
    5.本协议条款无论因何种原因部分无效或不可执行，其余条款仍有效，对双方具有约束力。

        </pre>
  </div>
</template>
<script>
export default {
  components: {
    
  },
  data() {
    return {
      msg: "用户协议",
    };
  },
  mounted() {
    let aarray = document.querySelectorAll("a");
    for (let i = 0; i < aarray.length; i++) {
      aarray[i].setAttribute("href", "/privacyprotocol");
    }
  },
};
</script>
<style scoped>
.allmsg {
  background-color: #ffffff;
  overflow: hidden;
}
pre {
  white-space: pre-wrap;
  word-wrap: break-word;
  line-height: 0.5rem;
  font-size: 0.25rem;
  padding: 0.2rem;
}

a {
  color: #40b8b4;
}
</style>